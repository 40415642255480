import { useEffect, useState } from 'react';

import { formatTime } from '@/components/LoginForm/LoginForm.helpers';
import { LockoutTimerProps } from '@/components/LoginForm/LoginForm.types';
import { Typography } from '@/components/ui/Typography/Typography.styles';

export const LockoutTimer: React.FC<LockoutTimerProps> = ({
  lockoutTime,
  onTimerEnd
}) => {
  const [timeRemaining, setTimeRemaining] = useState(lockoutTime - Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = lockoutTime - Date.now();
      setTimeRemaining(timeLeft);

      if (timeLeft <= 0) {
        clearInterval(interval);
        onTimerEnd();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lockoutTime, onTimerEnd]);

  return (
    <Typography data-cy="lockout-timer" variant="body3">
      {formatTime(timeRemaining)}
    </Typography>
  );
};
