import * as yup from 'yup';

import { emailSchema } from '@/utils/helpers/schemas';

export const loginFormSchema = yup.object({
  email: emailSchema,
  password: yup.string().required()
});

export type LoginFormFields = yup.InferType<typeof loginFormSchema>;

export interface LockoutTimerProps {
  lockoutTime: number;
  onTimerEnd: () => void;
}
