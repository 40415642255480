import React, { useState } from 'react';

import { HelperText } from '@/components/ui/HelperText/HelperText';
import { EyeIcon, EyeOffIcon } from '@/components/ui/Icons/Icons';
import { PasswordStrength } from '@/components/ui/PasswordStrength/PasswordStrength';
import { useCombinedRefs } from '@/hooks/useCombinedRefs';

import { getPlaceholder } from './Input.helpers';
import * as S from './Input.styles';
import { InputProps } from './Input.types';

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      helperText,
      helperTextDataCy,
      hasError,
      isPassword,
      withStrengthBar,
      ...props
    },
    ref
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs<HTMLInputElement | null>(ref, inputRef);
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      props.onFocus?.(e);
      setIsFocused(true);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      props.onBlur?.(e);
      setIsFocused(false);
    };

    const handleAutofillAnimation = () => {
      setIsFocused(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      props.onChange?.(value);

      if (combinedRef.current) {
        combinedRef.current.value = value;
      }
    };

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const placeholder = getPlaceholder(isFocused, label, props.placeholder);

    return (
      <S.InputWrapper>
        {label && (
          <S.InputLabel
            as="label"
            htmlFor={props.name}
            $isShifted={!!props.value || isFocused}
            $hasError={hasError}
          >
            {label}
          </S.InputLabel>
        )}

        <S.InputInnerContainer>
          <S.InputBox
            ref={combinedRef}
            {...props}
            type={isPassword && !showPassword ? 'password' : 'text'}
            placeholder={placeholder}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            id={props.name}
            onAnimationStart={handleAutofillAnimation}
            $hasError={hasError}
            $isPassword={isPassword}
          />
          {isPassword && props.value && (
            <S.TogglePasswordButton
              type="button"
              onClick={togglePasswordVisibility}
              data-cy="password-visibility-button"
            >
              {showPassword ? <EyeIcon /> : <EyeOffIcon />}
            </S.TogglePasswordButton>
          )}
        </S.InputInnerContainer>

        {isPassword && withStrengthBar && props.value && (
          // TODO make Input controlled and rewrite its tests
          <PasswordStrength password={props.value as string} />
        )}

        {helperText && !withStrengthBar && (
          <HelperText
            helperTextDataCy={helperTextDataCy}
            helperText={helperText}
            hasError={hasError}
          />
        )}
      </S.InputWrapper>
    );
  }
);

Input.displayName = 'Input';
