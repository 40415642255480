'use client';

import Link from 'next/link';
import { Trans, useTranslation } from 'react-i18next';

import { CONFIG } from '@/config';
import { useMediaQuery } from '@/hooks/useMediaQuery';

import * as S from './AuthFooter.styles';

export const AuthFooter = () => {
  const isMobile = useMediaQuery('mobile');
  const { i18n } = useTranslation();

  return (
    <S.FooterWrapper variant={isMobile ? 'body5' : 'body3'}>
      <Trans
        i18nKey="AuthFooter.text"
        components={{
          link1: (
            <S.LinkWrapper
              target="_blank"
              href={CONFIG.getTermsAndConditionsUrl(
                i18n.language === 'ru' ? 'ru.' : ''
              )}
              as={Link}
              variant={isMobile ? 'link_12' : 'link_14'}
            />
          )
        }}
      />
    </S.FooterWrapper>
  );
};
