import styled from 'styled-components';

import { Typography } from '@/components/ui/Typography/Typography.styles';

export const Text = styled(Typography)<{
  $hasError?: boolean;
}>`
  margin: 10px 0 0;
  color: var(--system-red);
`;
