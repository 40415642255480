import(/* webpackMode: "eager" */ "/vercel/path0/components/AuthFooter/AuthFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Wrapper","LeftSection","RightSection","FormContainer","FooterContainer"] */ "/vercel/path0/components/AuthWrapper/AuthWrapper.styles.ts");
;
import(/* webpackMode: "eager", webpackExports: ["SecondaryHeader"] */ "/vercel/path0/components/Headers/SecondaryHeader/SecondaryHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSwitcher"] */ "/vercel/path0/components/LanguageSwitcher/LanguageSwitcher.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/LoginForm/LoginForm.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
