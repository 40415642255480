import styled, { keyframes } from 'styled-components';

import { InputAppearance } from '@/components/ui/Input/Input.types';

const autofillKeyframe = keyframes`
  from {
    background-color: var(--bg-main);
  }
  to {
    background-color: var(--bg-main);
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const InputInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const TogglePasswordButton = styled.button`
  position: absolute;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const InputBox = styled.input<{
  $variant?: InputAppearance;
  $hasBorderBottom?: boolean;
  $hasError?: boolean;
  $isPassword?: boolean;
  $isBigText?: boolean;
}>`
  position: relative;
  background-color: var(--bg-main);
  border: 1px solid
    ${({ $hasError }) =>
      $hasError ? 'var(--system-red)' : 'var(--border-main)'};
  border-radius: 16px;
  padding: 25px ${({ $isPassword }) => ($isPassword ? '42px' : '20px')} 9px 20px;
  outline: 0;
  font-size: 15px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--textAndLabels-main);
  width: 100%;

  &::placeholder {
    color: var(--textAndLabels-tertiary);
  }

  &:focus {
    border: 1px solid
      ${({ $hasError }) =>
        $hasError ? 'var(--system-red)' : 'var(--custom-yellow)'};
  }

  &:-webkit-autofill {
    animation: ${autofillKeyframe};
  }

  transition: border 0.2s ease-in-out;
`;

export const InputLabel = styled.p<{
  $isShifted?: boolean;
  $hasError?: boolean;
}>`
  color: ${({ $hasError }) =>
    $hasError ? 'var(--system-red)' : 'var(--textAndLabels-tertiary)'};
  position: absolute;
  pointer-events: none;
  top: ${({ $isShifted }) => ($isShifted ? '8px' : '18px')};
  left: 20px;
  z-index: 1;
  font-size: ${({ $isShifted }) => ($isShifted ? '12px' : '15px')};
  transition: all 0.2s ease-in-out;
`;
