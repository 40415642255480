import styled, { css } from 'styled-components';

import { Button } from '@/components/ui/Button/Button';
import { Typography } from '@/components/ui/Typography/Typography.styles';
import { flexColumn } from '@/styles/common';

export const Form = styled.form`
  ${flexColumn}
  align-items: end;
  gap: 20px;
  width: 100%;
  color: var(--textAndLabels-main);
`;

export const Title = styled(Typography)`
  color: var(--textAndLabels-main);
  margin-bottom: 35px;
`;

export const PasswordWrapper = styled.div`
  width: 100%;
`;

export const TimerAndForgotWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ResetPasswordLink = styled(Typography).attrs({
  variant: 'link_15'
})<{
  $disabled: boolean;
}>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: var(--textAndLabels-tertiary);
      pointer-events: none;
    `}
`;

export const SubmitButton = styled(Button)``;
