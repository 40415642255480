import { FirstOption, Option, passwordStrength } from 'check-password-strength';

import { PasswordStrength } from '@/components/ui/PasswordStrength/PasswordStrength.types';

interface CustomOption {
  id: number;
  value: PasswordStrength;
  minDiversity: number;
  minLength: number;
}

const CUSTOM_OPTIONS: CustomOption[] = [
  {
    id: 0,
    value: 'low',
    minDiversity: 2,
    minLength: 6
  },
  {
    id: 1,
    value: 'medium',
    minDiversity: 3,
    minLength: 8
  },
  {
    id: 2,
    value: 'high',
    minDiversity: 4,
    minLength: 10
  }
];

export function checkPasswordStrength(password: string) {
  const result = passwordStrength(
    password,
    CUSTOM_OPTIONS as [FirstOption<string>, ...Option<string>[]]
  );

  return result.value as PasswordStrength;
}
