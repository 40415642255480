export const CONFIG = {
  protocol: process.env.BOSSAZ_PROTOCOL || 'https',
  host: process.env.BOSSAZ_HOST || 'bossaz-pr-1097.azstage.in',
  contacts: {
    tel: '(012) 526-44-11',
    telUrl: 'tel:+994125264411',
    email: 'boss@boss.az',
    emailUrl: 'mailto:boss@boss.az',
    instagramLink: 'https://www.instagram.com/boss.az.official/',
    facebookLink: 'https://www.facebook.com/www.boss.az/',
    telegramLink: 'https://t.me/bossaz'
  },
  getTermsAndConditionsUrl: (prefix = '') =>
    `https://${prefix}boss.az/terms-and-conditions`
};
