import { useTranslation } from 'react-i18next';

import * as S from '@/components/ui/PasswordStrength/PasswordStrength.styles';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { checkPasswordStrength } from '@/utils/helpers/checkPasswordStrength';

interface PasswordStrengthProps {
  password: string;
}

export const PasswordStrength: React.FC<PasswordStrengthProps> = ({
  password
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('mobile');

  const strength = checkPasswordStrength(password);

  return (
    <S.PasswordStrengthWrapper data-cy="strength-bar">
      <S.StrengthBarWrapper>
        {Array.from({ length: 3 }).map((_, index) => (
          <S.StrengthBar key={index} $strength={strength} />
        ))}
      </S.StrengthBarWrapper>
      <S.StrengthLabel
        $strength={strength}
        variant={isMobile ? 'caption1' : 'body2'}
      >
        {t('PasswordInput.strength')} {t(`PasswordInput.${strength}`)}
      </S.StrengthLabel>
    </S.PasswordStrengthWrapper>
  );
};
