import styled from 'styled-components';

import { PasswordStrength } from '@/components/ui/PasswordStrength/PasswordStrength.types';
import { Typography } from '@/components/ui/Typography/Typography.styles';
import { flexColumn } from '@/styles/common';

export const StrengthBarWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const StrengthBar = styled.div<{ $strength: PasswordStrength }>`
  height: 5px;
  border-radius: 20px;
  flex: 1;
  background-color: ${({ $strength }) =>
    $strength === 'low'
      ? 'var(--system-red)'
      : $strength === 'medium'
        ? 'var(--system-yellow)'
        : 'var(--system-green)'};

  &:nth-child(2) {
    background-color: ${({ $strength }) =>
      $strength === 'low'
        ? '#E9E9E9'
        : $strength === 'medium'
          ? 'var(--system-yellow)'
          : 'var(--system-green)'};
  }

  &:nth-child(3) {
    background-color: ${({ $strength }) =>
      $strength === 'high' ? 'var(--system-green)' : '#E9E9E9'};
  }
`;

export const StrengthLabel = styled(Typography)<{
  $strength: PasswordStrength;
}>`
  color: ${({ $strength }) => {
    const strengthColors = {
      low: 'var(--system-red)',
      medium: 'var(--system-yellow)',
      high: 'var(--system-green)'
    };

    return strengthColors[$strength] || 'var(--system-green)';
  }};
`;

export const PasswordStrengthWrapper = styled.div`
  margin-top: 10px;
  ${flexColumn}
  gap: 10px;
`;
