'use client';

import {
  ApolloError,
  MutationFunctionOptions,
  useMutation
} from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';

import {
  SignInMutation,
  SignInMutationVariables
} from '@/__generated_graphql__/graphql';
import { LockoutTimer } from '@/components/LoginForm/LockoutTimer';
import {
  LoginFormFields,
  loginFormSchema
} from '@/components/LoginForm/LoginForm.types';
import { HelperText } from '@/components/ui/HelperText/HelperText';
import { Input } from '@/components/ui/Input/Input';
import { ROUTES, STATUS_CODES, STORAGE_KEYS } from '@/constants/common';
import { SIGN_IN } from '@/graphql/mutations/auth.mutations';
import { useGraphQLErrorProcessor } from '@/hooks/useGraphQLErrorProcessor';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { encodeToBase64 } from '@/utils/helpers/base64';
import { setAuthCookie } from '@/utils/helpers/cookies';

import * as S from './LoginForm.styles';

const { LOCKOUT_END } = STORAGE_KEYS;
const { INVALID_CREDENTIALS } = STATUS_CODES;
const { PROFILE, LOGIN } = ROUTES;

export const LoginForm = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('mobile');
  const [lockoutEndTime, setLockoutEndTime] = useLocalStorage<number | null>(
    LOCKOUT_END,
    null
  );

  const handleLockoutEnd = () => {
    setLockoutEndTime(null);
  };

  useEffect(() => {
    handleLockoutEnd();
  }, []);

  const handleGraphQLErrors = useGraphQLErrorProcessor();

  const { push } = useRouter();
  const searchParams = useSearchParams();
  const emailFromParams = searchParams.get('email') || '';

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting, errors },
    trigger,
    watch
  } = useForm({
    mode: 'onBlur',
    defaultValues: { email: emailFromParams, password: '' },
    resolver: yupResolver(loginFormSchema)
  });

  const [signInMutation] = useMutation<SignInMutation, SignInMutationVariables>(
    SIGN_IN
  );

  const onSubmit = async ({ email, password }: LoginFormFields) => {
    try {
      await signInMutation({
        variables: {
          email,
          password
        }
      } as MutationFunctionOptions<SignInMutation, SignInMutationVariables>);
      // TODO remove this after discussion if back and front are on the same domain
      setAuthCookie('isAuth');
      push(PROFILE);
    } catch (error) {
      handleGraphQLErrors({
        error: error as ApolloError,
        onLockout: setLockoutEndTime,
        statusMessages: {
          [INVALID_CREDENTIALS]: t('LoginForm.credentials_error')
        }
      });
    }
  };

  return (
    <>
      <S.Title variant={isMobile ? 'h1' : 'h0'}>{t('LoginForm.title')}</S.Title>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              onChange={e => {
                field.onChange(e);
                if (lockoutEndTime) {
                  handleLockoutEnd();
                }
              }}
              label="Email"
              hasError={Boolean(errors.email)}
              helperText={t(errors.email?.message ?? '')}
              helperTextDataCy="email-helper-text"
              data-cy="email-input"
            />
          )}
        />

        <S.PasswordWrapper>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onChange={e => {
                  field.onChange(e);
                  trigger('password');
                  if (lockoutEndTime) {
                    handleLockoutEnd();
                  }
                }}
                isPassword
                label={t('LoginForm.password')}
                data-cy="password-input"
              />
            )}
          />

          {lockoutEndTime && (
            <HelperText
              helperTextDataCy="limit-helper-text"
              hasError
              helperText={t('LoginForm.limit_error')}
            />
          )}
        </S.PasswordWrapper>

        <S.TimerAndForgotWrapper>
          {lockoutEndTime ? (
            <LockoutTimer
              lockoutTime={lockoutEndTime}
              onTimerEnd={handleLockoutEnd}
            />
          ) : (
            <div />
          )}

          <S.ResetPasswordLink
            $disabled={!!lockoutEndTime}
            data-cy="reset-password-link"
            as={Link}
            href={{
              pathname: ROUTES.RESET_PASSWORD,
              query: { email: watch('email'), returnTo: encodeToBase64(LOGIN) }
            }}
          >
            {t('LoginForm.forgot_password')}
          </S.ResetPasswordLink>
        </S.TimerAndForgotWrapper>

        <S.SubmitButton
          fullWidth
          isLoading={isSubmitting}
          disabled={!isValid || !!lockoutEndTime}
          type="submit"
          data-cy="login-button"
        >
          {t('LoginForm.button_label')}
        </S.SubmitButton>
      </S.Form>
    </>
  );
};
