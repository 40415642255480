import { useMediaQuery } from '@/hooks/useMediaQuery';

import { Text } from './HelperText.styles';

interface HelperTextProps {
  helperText: string;
  helperTextDataCy?: string;
  hasError?: boolean;
}

export const HelperText: React.FC<HelperTextProps> = ({
  helperText,
  helperTextDataCy,
  hasError
}) => {
  const isMobile = useMediaQuery('mobile');

  return (
    <Text
      data-cy={helperTextDataCy}
      $hasError={hasError}
      variant={isMobile ? 'caption1' : 'body2'}
    >
      {helperText}
    </Text>
  );
};
