'use client';

import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';
import { flexCenterAligned } from '@/styles/common';

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  padding: 20px;
  gap: 20px;
  background-color: var(--bg-main);

  @media (max-width: ${BREAKPOINTS.mobile}) {
    padding: 10px;
  }
`;

export const LeftSection = styled.div`
  width: 33.5%;
  max-width: 600px;
  border-radius: 28px;
  background-image: url('/images/auth-wrapper.png');
  background-size: cover;
  background-position: center;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    display: none;
  }
`;

export const RightSection = styled.div`
  flex-grow: 1;
  background-color: var(--bg-card);
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const CrossButton = styled.button`
  width: 44px;
  height: 44px;
  ${flexCenterAligned}
  background: none;
  border: none;
  border-radius: 50%;
  color: var(--textAndLabels-tertiary);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--bg-tertiary);
    color: var(--system-yellow);
  }
`;

export const FormContainer = styled.div`
  width: 373px;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    padding: 20px;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 40px;
  padding: 20px;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    padding-top: 0;
    gap: 30px;
  }
`;
