import styled from 'styled-components';

import { Typography } from '@/components/ui/Typography/Typography.styles';

export const FooterWrapper = styled(Typography)`
  color: var(--textAndLabels-tertiary);
`;

export const LinkWrapper = styled(Typography)`
  color: var(--textAndLabels-main);
`;
